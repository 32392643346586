import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { useConfirm } from 'material-ui-confirm';
import { Link } from 'react-router-dom';

import { IconButton, Tooltip } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import PostAddIcon from '@material-ui/icons/PostAdd';

import ProjectModal from './modals/ProjectModal';
import { IProject } from '../stores/ProjectsListStore';

import '../styles/views/ProjectsList.scss';
import '../styles/views/Table.scss';

const ProjectsList: React.FC = inject('ProjectsListStore')(
    observer(({ ProjectsListStore }: any) => {
        const { t } = useTranslation();
        const confirm = useConfirm();

        !ProjectsListStore.isLoaded && ProjectsListStore.getList();

        useEffect(() => {
            return () => {
                ProjectsListStore.reset();
            };
        }, [ProjectsListStore]);

        const renderTable = (data: IProject[]) => {
            return (
                <>
                    <div className="table-head">
                        <div className="table-head__column">{t('projects.name')}</div>
                        <div className="table-head__column">{t('projects.description')}</div>
                        <div className="table-head__column button"></div>
                        <div className="table-head__column button"></div>
                    </div>
                    <div className="table-body">
                        {data.map((project) => (
                            <div className="table-body__row" key={project.id}>
                                <div className="table-body__column">
                                    <Link to={`/project/${project.Name}/documnetation`} className="project-link">
                                        {project.Name}
                                    </Link>
                                </div>
                                <div className="table-body__column">{project.Description}</div>
                                <div className="table-body__column button">
                                    <Tooltip title={String(t('projects.edit-project'))} aria-label="edit-user">
                                        <IconButton
                                            aria-label="delete"
                                            className="table-body__column-button"
                                            onClick={() => {
                                                ProjectsListStore.showModal(project.id);
                                            }}
                                        >
                                            <EditOutlinedIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className="table-body__column button">
                                    <Tooltip title={String(t('projects.delete-project'))} aria-label="delete-user">
                                        <IconButton
                                            aria-label="delete"
                                            className="table-body__column-button"
                                            onClick={() => {
                                                confirm({
                                                    title: t('projects.delete-confirm-title'),
                                                    description: t('projects.delete-confirm-description'),
                                                    confirmationText: t('doc.delete-ok'),
                                                    cancellationText: t('doc.delete-cancel'),
                                                })
                                                    .then(() => {
                                                        ProjectsListStore.deleteProject(project.id);
                                                    })
                                                    .catch(() => {});
                                            }}
                                        >
                                            <DeleteOutlineOutlinedIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            );
        };
        return (
            <div className="main-container">
                <div className="projectslist-container">
                    <div className="projectslist">
                        <div className="projectslist__header">
                            <h1>{t('projects.title')}</h1>
                            <Tooltip title={String(t('projects.add-project'))} aria-label="add user">
                                <IconButton
                                    aria-label="add"
                                    className="projectslist__header-button"
                                    onClick={() => {
                                        ProjectsListStore.showModal();
                                    }}
                                >
                                    <PostAddIcon style={{ color: 'rgb(25, 118, 210)' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="table-list">{renderTable(ProjectsListStore.listOfProjects)}</div>
                    </div>
                </div>
                <ProjectModal />
            </div>
        );
    }),
);

export default ProjectsList;
