import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams, Link, useHistory, useRouteMatch } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import {
    List,
    ListItem,
    ListItemText,
    Collapse,
    Menu,
    MenuItem,
    ListItemIcon,
    IconButton,
    Tooltip,
} from '@material-ui/core';
import {
    ExpandLess,
    ExpandMore,
    MoreVert as MoreVertIcon,
    Delete as DeleteIcon,
    Publish as PublishIcon,
    FileCopy as FileCopyIcon,
} from '@material-ui/icons';
import { useConfirm } from 'material-ui-confirm';
import { HashLink } from 'react-router-hash-link';
import * as _ from 'lodash';

import { IDocListItem, IFullDoc } from '../stores/DocumentationStore';

import '../styles/views/Documentation.scss';
import { useTranslation } from 'react-i18next';

interface DocListProps {
    startURL: string;
    data: IDocListItem[];
    selected: string;
    onClick: Function;
}

interface ColapseListItemProps extends IDocListItem {
    startURL: string;
    selectedName: string;
    onClick: Function;
}

interface DocumentationProps {
    DocumentationStore?: any;
    LoginStore?: any;
    AppStore?: any;
}

const CollapseListItem: React.FC<ColapseListItemProps> = (props) => {
    const [open, setOpen] = useState(true);
    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            <ListItem button onClick={handleClick}>
                <ListItemText primary={props.name} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto">
                <List component="div" disablePadding>
                    {props.items.map((item) => (
                        <Link
                            key={item.id}
                            to={`${props.startURL}/${item.name}`}
                            className="documentation__nav__collapse-link"
                            onClick={() => {
                                props.onClick();
                            }}
                        >
                            <ListItem
                                button
                                selected={props.selectedName === item.name}
                                className="documentation__nav__collapse-item"
                            >
                                <ElipsisWrapper text={item.name} className="documentation__nav__collapse-text" />
                                {/* <ListItemText primary={item.name} className="documentation__nav__collapse-text" /> */}
                            </ListItem>
                        </Link>
                    ))}
                </List>
            </Collapse>
        </>
    );
};

const DocList: React.FC<DocListProps> = (props) => {
    return (
        <List component="nav" aria-labelledby="nested-list-subheader" className="documentation__nav">
            {props.data.map((item) => (
                <CollapseListItem
                    key={item.name}
                    startURL={props.startURL}
                    name={item.name}
                    items={item.items}
                    selectedName={props.selected}
                    onClick={props.onClick}
                />
            ))}
        </List>
    );
};

const Documentation: React.FC<DocumentationProps> = inject(
    'DocumentationStore',
    'LoginStore',
    'AppStore',
)(
    observer(({ DocumentationStore, LoginStore, AppStore }) => {
        let { docname } = useParams<{ docname: string }>();
        const history = useHistory();
        const match = useRouteMatch();
        const { t } = useTranslation();
        const confirm = useConfirm();
        const firstLoad = useRef<boolean>(true);
        const baseURL = docname ? match.path.slice(0, match.path.length - 9) : match.url;

        !DocumentationStore.isLoaded && DocumentationStore.getList();
        docname && DocumentationStore.getDoc(docname, history);

        const scrollToTop = () => {
            if (scroll.current) {
                scroll.current.scrollTo(0, 0);
                setHash('');
            }
        };

        /*-------------------- CONTENT LOGIC -------------------*/

        const scroll = useRef<HTMLDivElement>(null);

        /* Content refs */
        const r_input_params = useRef<HTMLDivElement>(null);
        const r_output_params = useRef<HTMLDivElement>(null);
        const r_references = useRef<HTMLDivElement>(null);
        const r_syntax = useRef<HTMLDivElement>(null);
        const r_history = useRef<HTMLDivElement>(null);
        const refs: { [prop: string]: React.RefObject<HTMLDivElement> } = useMemo(
            () => ({
                'input-params': r_input_params,
                'output-params': r_output_params,
                references: r_references,
                syntax: r_syntax,
                history: r_history,
            }),
            [],
        );
        const isScrollToRef = useRef<boolean>(false);
        const Timer = useRef<any>(null);
        const [hash, setHash] = useState<string>('');

        const onScroll = _.throttle(() => {
            if (!isScrollToRef.current) {
                // don`t check while scroll by click
                let offset: number = 100;

                Object.values(refs).some((ref) => {
                    if (!ref.current) {
                        return false;
                    }
                    const top = ref.current?.getBoundingClientRect().top;
                    const isVisible = top + offset >= 0 && top - offset <= window.innerHeight;
                    if (isVisible) {
                        if (hash !== ref.current.id) {
                            setHash(ref.current.id);
                        }
                    }
                    return isVisible;
                });
            } else {
                if (Timer.current !== null) {
                    clearTimeout(Timer.current);
                }
                //reset isScrollToRef flag on scroll stop
                Timer.current = setTimeout(function () {
                    isScrollToRef.current = false;
                }, 150);
            }
        }, 100);

        /*---------------- END OF CONTENT LOGIC ----------------*/

        //more button config
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const isMenuOpen = Boolean(anchorEl);

        const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        };
        const handleMenuClose = () => {
            setAnchorEl(null);
        };
        //end of more button config

        useEffect(() => {
            let v_scroll = scroll.current;
            if (firstLoad.current) {
                firstLoad.current = false;
                if (history.location.hash) {
                    let hash = history.location.hash.slice(1);
                    setHash(hash);
                    let node = refs[hash];
                    if (node) {
                        setTimeout(() => {
                            node.current?.scrollIntoView({ behavior: 'smooth' });
                        }, 1000);
                    }
                }
                v_scroll?.addEventListener('scroll', onScroll);
            }
            return () => {
                DocumentationStore.reset();
                v_scroll?.removeEventListener('scroll', onScroll);
            };
        }, [DocumentationStore, history, refs]);

        const renderList = () => {
            return (
                <div className="doc-list">
                    {DocumentationStore.groupedDocList.map((group: IDocListItem) => (
                        <div className="doc-list__group" key={group.name}>
                            <h2 className="doc-list__group__title">{group.name}</h2>
                            <div className="doc-list__group__items">
                                {group.items.map((item) => (
                                    <div key={item.id} className="doc-list__group__item">
                                        <Link to={`${match.url}/${item.name}`}>
                                            <ElipsisWrapper text={item.name} />
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            );
        };

        const renderDoc = (data: IFullDoc) => {
            const renderParam = (name: string, data: any, id: string, ref: React.RefObject<HTMLDivElement>) => (
                <div className="doc__body-param" id={id} ref={ref}>
                    <h1 className="doc__body-param__name">{name}</h1>
                    <div className="doc__body-param__table">
                        <div className="table-header">
                            <div className="table-header__column">{t('doc.param-name')}</div>
                            <div className="table-header__column">{t('doc.param-type')}</div>
                            <div className="table-header__column">{t('doc.param-comment')}</div>
                        </div>
                        <div className="table-body">
                            {data.map((param: any) => (
                                <div className="table-body__row" key={param.id}>
                                    <div className="table-body__column">{param.ParamName}</div>
                                    <div className="table-body__column">{param.ParamType}</div>
                                    <div className="table-body__column">{param.Comment.trim()}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            );
            const renderReferences = (name: string, data: any, id: string, ref: React.RefObject<HTMLDivElement>) => (
                <div className="doc__body-param" id={id} ref={ref}>
                    <h1 className="doc__body-param__name">{name}</h1>
                    <div className="doc__body-param__table">
                        <div className="table-header">
                            <div className="table-header__column">{t('doc.param-name')}</div>
                            <div className="table-header__column">{t('doc.param-type')}</div>
                        </div>
                        <div className="table-body">
                            {data.map((ref: any) => (
                                <div className="table-body__row" key={ref.id}>
                                    <div className="table-body__column">{ref.Name}</div>
                                    <div className="table-body__column">{ref.Type}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            );
            const renderSyntax = (
                name: string,
                syntaxString: string,
                id: string,
                ref: React.RefObject<HTMLDivElement>,
            ) => (
                <div className="doc__syntax" id={id} ref={ref}>
                    <h1 className="doc__syntax__name">{name}</h1>

                    <div className="doc__syntax__example--container">
                        <div className="doc__syntax__example--buttons">
                            <span></span>
                            <Tooltip title={String(t('doc.copy'))} aria-label="delete-user">
                                <IconButton
                                    aria-label="copy"
                                    className="copy-syntax__button"
                                    onClick={() => {
                                        navigator.clipboard
                                            .writeText(syntaxString)
                                            .then((res) => {
                                                AppStore.showMessage('doc.copy-success', 'success');
                                            })
                                            .catch((err) => {
                                                AppStore.showMessage('doc.copy-failed', 'error');
                                            });
                                    }}
                                >
                                    <FileCopyIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="doc__syntax__example--string">{syntaxString}</div>
                    </div>
                </div>
            );
            const renderHistory = (name: string, data: any, id: string, ref: React.RefObject<HTMLDivElement>) => (
                <div className="doc__history" id={id} ref={ref}>
                    <h1 className="doc__history__name">{name}</h1>
                    <div className="doc__history__items">
                        {data.map((history: any) => (
                            <div className="doc__history__item" key={history.id}>
                                {history.Text}
                            </div>
                        ))}
                    </div>
                </div>
            );

            const renderMenu = () => {
                const menuId = 'primary-doc-settings-menu';
                return (
                    <>
                        <IconButton
                            // edge="end"
                            aria-label="more-button"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="default"
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            id={menuId}
                            keepMounted
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={isMenuOpen}
                            onClose={handleMenuClose}
                        >
                            <MenuItem
                                onClick={() => {
                                    confirm({
                                        title: t('doc.delete-confirm-title'),
                                        description: t('doc.delete-confirm-description'),
                                        confirmationText: t('doc.delete-ok'),
                                        cancellationText: t('doc.delete-cancel'),
                                    })
                                        .then(() => {
                                            DocumentationStore.deleteDoc(history);
                                            handleMenuClose();
                                        })
                                        .catch(() => {
                                            handleMenuClose();
                                        });
                                }}
                            >
                                <ListItemIcon>
                                    <DeleteIcon fontSize="small" />
                                </ListItemIcon>
                                {t('doc.delete')}
                            </MenuItem>
                        </Menu>
                    </>
                );
            };

            const renderContent = (name: string, data: IFullDoc) => (
                <div className="content">
                    <h3 className="content__name">{name}</h3>
                    <ul className="content__list">
                        <HashLink
                            smooth
                            to={`${match.url}#input-params`}
                            key="input-params"
                            className={hash === 'input-params' ? 'active' : ''}
                            onClick={() => {
                                isScrollToRef.current = true;
                                setHash('input-params');
                            }}
                        >
                            Input parameters
                        </HashLink>
                        <HashLink
                            smooth
                            to={`${match.url}#output-params`}
                            key="output-params"
                            className={hash === 'output-params' ? 'active' : ''}
                            onClick={() => {
                                isScrollToRef.current = true;
                                setHash('output-params');
                            }}
                        >
                            Output parameters
                        </HashLink>
                        {Array.isArray(data.References) && data.References.length ? (
                            <HashLink
                                smooth
                                to={`${match.url}#references`}
                                key="references"
                                className={hash === 'references' ? 'active' : ''}
                                onClick={() => {
                                    isScrollToRef.current = true;
                                    setHash('references');
                                }}
                            >
                                References
                            </HashLink>
                        ) : (
                            ''
                        )}
                        <HashLink
                            smooth
                            to={`${match.url}#syntax`}
                            key="syntax"
                            className={hash === 'syntax' ? 'active' : ''}
                            onClick={() => {
                                isScrollToRef.current = true;
                                setHash('syntax');
                            }}
                        >
                            Syntax
                        </HashLink>
                        <HashLink
                            smooth
                            to={`${match.url}#history`}
                            key="history"
                            className={hash === 'history' ? 'active' : ''}
                            onClick={() => {
                                isScrollToRef.current = true;
                                setHash('history');
                            }}
                        >
                            History
                        </HashLink>
                    </ul>
                </div>
            );

            return (
                <div className="doc-container">
                    <div className="doc-wrapper">
                        <div className="doc">
                            <div className="doc__header">
                                <div className="doc__header__name-container">
                                    <h1 className="doc__header__name">
                                        <span className="doc-type">{data.Type}:</span>
                                        <Tooltip title={data.Name}>
                                            <span>{data.Name}</span>
                                        </Tooltip>
                                    </h1>
                                    {LoginStore.isLogged &&
                                    (LoginStore.role === 'Admin' || LoginStore.role === 'Moderator')
                                        ? renderMenu()
                                        : ''}
                                </div>

                                <p className="doc__header__description">{data.Description}</p>
                            </div>
                            {renderParam('Input parameters', data.InputParameters, 'input-params', r_input_params)}
                            {renderParam('Output parameters', data.OutputParameters, 'output-params', r_output_params)}
                            {Array.isArray(data.References) && data.References.length
                                ? renderReferences('References', data.References, 'references', r_references)
                                : ''}
                            {renderSyntax('Syntax', DocumentationStore.syntaxString, 'syntax', r_syntax)}
                            {renderHistory('History', data.History, 'history', r_history)}
                        </div>
                    </div>
                    <div className="doc__content-container">
                        <div className="doc__content">{renderContent('Contents', data)}</div>
                    </div>
                </div>
            );
        };

        return (
            <div className="documentation">
                <div className={`documentation__nav-container ${DocumentationStore.isDocListOpen ? 'active' : ''}`}>
                    <DocList
                        startURL={baseURL}
                        data={DocumentationStore.groupedDocList}
                        selected={docname}
                        onClick={() => {
                            DocumentationStore.setIsDocListOpen(false);
                            scrollToTop();
                        }}
                    />
                </div>

                <div className="documentation__section-container" ref={scroll} id="scroll">
                    <section className="documentation__section">
                        {docname ? renderDoc(DocumentationStore.fullDoc) : renderList()}
                    </section>

                    {LoginStore.isLogged && (LoginStore.role === 'Admin' || LoginStore.role === 'Moderator') ? (
                        <Tooltip title={String(t('doc.upload'))} aria-label="add">
                            <div
                                className={`fab-button-container ${DocumentationStore.uploadPending ? 'pending' : ''}`}
                            >
                                <input
                                    type="file"
                                    multiple
                                    accept="application/json"
                                    style={{ display: 'none' }}
                                    id="icon-button-file"
                                    disabled={DocumentationStore.uploadPending}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if (e.target.files) {
                                            DocumentationStore.uploadDocs(e.target.files, history);
                                        }
                                    }}
                                />
                                <label htmlFor="icon-button-file">
                                    <IconButton aria-label="add" component="span" className="fab-button">
                                        <PublishIcon color="inherit" />
                                    </IconButton>
                                </label>
                            </div>
                        </Tooltip>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        );
    }),
);
export default Documentation;

const ElipsisWrapper: React.FC<{ text: string; className?: string }> = (props) => {
    const [withTooltip, showTooltip] = useState<boolean>(false);
    const self = useRef<HTMLSpanElement>(null);
    const firstLoad = useRef<boolean>(true);
    const isToolTip = () => {
        if (self.current && self.current?.offsetWidth < self.current?.scrollWidth) {
            showTooltip(true);
        } else {
            showTooltip(false);
        }
    };

    useEffect(() => {
        if (firstLoad.current) {
            isToolTip();
            firstLoad.current = false;
        }
        window.addEventListener('resize', isToolTip);
        return () => {
            window.removeEventListener('resize', isToolTip);
        };
    });

    return withTooltip ? (
        <Tooltip title={props.text} aria-label="info">
            <span ref={self} className={`elipsis-wrapper ${props.className ? props.className : ''}`}>
                {props.text}
            </span>
        </Tooltip>
    ) : (
        <span ref={self} className={`elipsis-wrapper ${props.className ? props.className : ''}`}>
            {props.text}
        </span>
    );
};
