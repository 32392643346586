import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { reaction } from 'mobx';
import { BrowserRouter } from 'react-router-dom';
import { ConfirmProvider } from 'material-ui-confirm';

import i18n from './i18n';
import App from './App';

import RootStore from './stores/RootStore';

declare const window: any;

const stores = {
    RootStore: RootStore,
    AppStore: RootStore.AppStore,
    LoginStore: RootStore.LoginStore,
    DocumentationStore: RootStore.DocumentationStore,
    UsersStore: RootStore.UsersStore,
    ProjectStore: RootStore.ProjectStore,
    ProjectsListStore: RootStore.ProjectsListStore,
};

reaction(
    () => RootStore.AppStore.locale,
    (locale) => {
        i18n.changeLanguage(locale);
    },
);

ReactDOM.render(
    <Provider {...stores}>
        <ConfirmProvider>
            <BrowserRouter basename={window.realbasepage}>
                <App />
            </BrowserRouter>
        </ConfirmProvider>
    </Provider>,

    document.getElementById('root'),
);
