import { types } from 'mobx-state-tree';
import ApiClient from '../services/ApiClient';

const ProjectStore = types
    .model({
        projectName: types.optional(types.string, ''),
        isExist: types.optional(types.boolean, false),
        isPending: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        setPending(value: boolean) {
            self.isPending = value;
        },
        setExist(value: boolean) {
            self.isExist = value;
        },
        reset() {
            self.isExist = false;
            self.isPending = false;
            self.projectName = '';
        },
    }))
    .actions((self) => ({
        setName(value: string) {
            if (!self.isPending && value && self.projectName !== value) {
                self.setPending(true);
                self.projectName = value;
                ApiClient.get('/Project/IsExist', { projectName: value })
                    .then((res: { result: boolean }) => {
                        if (res.result) {
                            sessionStorage.setItem('projectName', value);
                            self.setExist(true);
                        } else {
                            self.setExist(false);
                        }
                        self.setPending(false);
                    })
                    .catch((err) => {
                        self.setExist(false);
                        self.setPending(false);
                    });
            }
        },
    }));

export default ProjectStore.create();
