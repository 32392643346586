import { types, Instance } from 'mobx-state-tree';

import FieldValidator from '../services/FieldValidator';
import ApiClient from '../services/ApiClient';
import AppStore from './AppStore';

export interface IUser extends Instance<typeof User> {}
export interface IReturnUser {
    readonly id: number;
    readonly login: string;
    readonly role: string;
}
export interface IRole {
    id: number;
    role: string;
}
export interface IStatus {
    status: 0 | 1 | 2;
    message: string;
}

const ListUser = types.model({
    id: types.number,
    login: types.string,
    role: types.string,
});

const User = types.model({
    id: types.optional(types.number, -1),
    login: types.optional(types.string, ''),
    password: types.optional(types.string, ''),
    role: types.optional(types.string, 'User'),
});

const Role = types.model({
    id: types.optional(types.number, 0),
    role: types.optional(types.string, ''),
});

const Modal = types
    .model({
        isOpen: types.optional(types.boolean, false),
        user: types.optional(User, {}),
        loginError: types.optional(types.boolean, false),
        passwordError: types.optional(types.boolean, false),
        userExist: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        show() {
            self.isOpen = true;
        },
        hide() {
            self.isOpen = false;
            this.reset();
        },
        setUser(data: IUser) {
            self.user = data;
        },
        setUserExist(value: boolean) {
            self.userExist = true;
        },
        reset() {
            self.user.id = -1;
            self.user.login = '';
            self.user.password = '';
            self.user.role = 'User';
            self.userExist = false;
            self.loginError = false;
            self.passwordError = false;
        },
        handleChange(field: string, value: string) {
            if (field) {
                switch (field) {
                    case 'login':
                        self.user.login = value.toLowerCase();
                        break;
                    case 'password':
                        self.user.password = value;
                        break;
                    case 'role':
                        self.user.role = value;
                        break;
                    default:
                        break;
                }
            }
        },
        validate() {
            let valid = true;
            if (!FieldValidator.isNotEmpty(self.user.login)) {
                valid = false;
                self.loginError = true;
            } else {
                self.loginError = false;
            }
            if (self.user.id === -1) {
                let match = self.user.password.match(
                    /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/,
                );
                if (!FieldValidator.isNotEmpty(self.user.password) || match === null) {
                    valid = false;
                    self.passwordError = true;
                }
            }
            if (self.user.id !== -1 && FieldValidator.isNotEmpty(self.user.password)) {
                let match = self.user.password.match(
                    /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/,
                );
                if (match === null) {
                    valid = false;
                    self.passwordError = true;
                }
            }
            return valid;
        },
    }));

const UsersStore = types
    .model({
        isLoaded: types.optional(types.boolean, false),
        isPending: types.optional(types.boolean, false),
        listOfUsers: types.optional(types.frozen(types.array(ListUser)), []),
        roles: types.optional(types.frozen(types.array(Role)), []),
        modal: types.optional(Modal, {}),
    })
    .actions((self) => ({
        setLoaded(value: boolean) {
            self.isLoaded = value;
        },
        setPending(value: boolean) {
            self.isPending = value;
        },
        setList(data: IReturnUser[]) {
            self.listOfUsers = data;
        },
        setRoles(data: IRole[]) {
            self.roles = data;
        },
        reset() {
            self.isLoaded = false;
            self.isPending = false;
        },
    }))
    .actions((self) => ({
        getList(reset: boolean = false) {
            if ((!self.isLoaded && !self.isPending) || reset) {
                self.setPending(true);
                ApiClient.get('/User/GetUsersList')
                    .then((res: IReturnUser[]) => {
                        let list = res;
                        ApiClient.get('/User/GetUsersRoleList').then((res: IRole[]) => {
                            self.setRoles(res);
                            self.setList(list);
                            self.setLoaded(true);
                            self.setPending(false);
                        });
                    })
                    .catch((err) => {
                        self.setLoaded(true);
                        self.setPending(false);
                    });
            }
        },
        showModal(id?: number | undefined) {
            if (id !== undefined) {
                const user = self.listOfUsers?.find((user) => user.id === id);
                if (user) {
                    self.modal.setUser(Object.assign(user, { password: '' }));
                    self.modal.show();
                }
            } else {
                self.modal.show();
            }
        },
    }))
    .actions((self) => ({
        createUser() {
            if (self.modal.validate()) {
                ApiClient.post('/User/CreateUser', {
                    login: self.modal.user.login,
                    password: self.modal.user.password,
                    role: self.modal.user.role,
                })
                    .then((res: IStatus) => {
                        if (res.status === 1) {
                            AppStore.showMessage('users.user-create-success', 'success');
                            self.modal.hide();
                            self.getList(true);
                        } else if (res.status === 0) {
                            self.modal.setUserExist(true);
                        } else if (res.status === 2) {
                            AppStore.showMessage('users.user-create-error', 'error');
                            self.modal.hide();
                        }
                    })
                    .catch((error) => {
                        AppStore.showMessage('users.user-create-error', 'error');
                        self.modal.hide();
                    });
            }
        },
        updateUser() {
            if (self.modal.validate()) {
                let data: any = {
                    id: self.modal.user.id,
                    login: self.modal.user.login,
                    role: self.modal.user.role,
                };
                if (self.modal.user.password !== '') data.password = self.modal.user.password;
                ApiClient.post('/User/UpdateUser', data)
                    .then((res: IStatus) => {
                        if (res.status === 1) {
                            AppStore.showMessage('users.user-update-success', 'success');
                            self.modal.hide();
                            self.getList(true);
                        } else if (res.status === 0) {
                            AppStore.showMessage('users.user-update-error', 'error');
                            self.modal.hide();
                        } else if (res.status === 2) {
                            AppStore.showMessage('users.user-update-error', 'error');
                            self.modal.hide();
                        }
                    })
                    .catch((err) => {
                        AppStore.showMessage('users.user-update-error', 'error');
                        self.modal.hide();
                    });
            }
        },
        deleteUser(id: number) {
            const user = self.listOfUsers?.find((user) => user.id === id);
            if (user) {
                ApiClient.delete('/User/DeleteUser', { id: id })
                    .then((res) => {
                        AppStore.showMessage('users.user-delete-success', 'success');
                        self.getList(true);
                    })
                    .catch((err) => {
                        AppStore.showMessage('users.user-delete-error', 'error');
                    });
            }
        },
    }))
    .actions((self) => ({
        handleAccept() {
            if (self.modal.user.id === -1) {
                self.createUser();
            } else {
                self.updateUser();
            }
        },
    }));

export default UsersStore.create();
