export default class FieldValidator {
    static isNotEmpty(value) {
        return value !== '';
    };
    static isNumber(value) {
        return typeof value === 'number' && isFinite(value);
    };
    static isInteger(value) {
        return Number.isInteger(value);
    };
    static isHexKey(number) {
        let hex = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'];
        if (number.length === 32) {
            for (let num of number) {
                if (!hex.includes(num)) return false;
            }
            return true;
        }
        if (number.length === 0) {
            return true;
        }
        return false;
    }
}