import { types } from 'mobx-state-tree';
import Cookies from 'js-cookie';

import FieldValidator from '../services/FieldValidator';
import ApiClient from '../services/ApiClient';

interface IAuthResponce {
    accessToken: string;
    role: string;
}

const LoginModal = types
    .model({
        isOpen: types.optional(types.boolean, false),
        login: types.optional(types.string, ''),
        password: types.optional(types.string, ''),
        loginError: types.optional(types.boolean, false),
        passwordError: types.optional(types.boolean, false),
        userDoesntExist: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        show() {
            self.isOpen = true;
        },

        setLogin(value: string) {
            self.login = value;
            if (self.userDoesntExist) self.userDoesntExist = false;
        },
        setPassword(value: string) {
            self.password = value;
            if (self.userDoesntExist) self.userDoesntExist = false;
        },
        setUserDoesntExist(value: boolean) {
            self.userDoesntExist = value;
        },
        reset() {
            self.login = '';
            self.password = '';
            self.loginError = false;
            self.passwordError = false;
            self.userDoesntExist = false;
        },
    }))
    .actions((self) => ({
        hide() {
            self.isOpen = false;
            self.reset();
        },
    }));

const LoginStore = types
    .model({
        isLogged: types.optional(types.boolean, Cookies.get('_DOC_AT') ? true : false),
        role: types.optional(types.string, Cookies.get('_DOC_ROLE') ?? ''),
        modal: types.optional(LoginModal, {}),
    })
    .actions((self) => ({
        setLogged(value: boolean) {
            self.isLogged = value;
        },
        setRole(value: string) {
            self.role = value;
        },
    }))

    .actions((self) => ({
        handleLogIn() {
            self.modal.loginError = !FieldValidator.isNotEmpty(self.modal.login);
            self.modal.passwordError = !FieldValidator.isNotEmpty(self.modal.password);

            if (!self.modal.loginError && !self.modal.passwordError) {
                //auth
                ApiClient.post('/Auth/SignIn', { login: self.modal.login, password: self.modal.password })
                    .then((res: IAuthResponce) => {
                        self.setLogged(true);
                        self.setRole(res.role);
                        //make token expires date
                        let date = new Date();
                        date.setDate(date.getDate() + 1);
                        //set token to the cookies
                        Cookies.set('_DOC_AT', res.accessToken, { expires: date });
                        //set role to the cookies
                        Cookies.set('_DOC_ROLE', res.role, { expires: date });

                        //clear login page fields
                        self.modal.hide();
                    })
                    .catch((err) => {
                        self.modal.setUserDoesntExist(true);
                    });
            }
        },
        handleLogOut() {
            self.setLogged(false);
            //remove token from cookies
            Cookies.remove('_DOC_AT');
            //remove role from cookies
            Cookies.remove('_DOC_ROLE');
            //redirect to login page
            self.setLogged(false);
        },
    }));

export default LoginStore.create();
