import AppStore from './AppStore';
import LoginStore from './LoginStore';
import DocumentationStore from './DocumentationStore';
import UsersStore from './UsersStore';
import ProjectStore from './ProjectStore';
import ProjectsListStore from './ProjectsListStore';

class RootStore {
    AppStore: any;
    LoginStore: any;
    DocumentationStore: any;
    UsersStore: any;
    ProjectStore: any;
    ProjectsListStore: any;
    constructor() {
        this.AppStore = AppStore;
        this.LoginStore = LoginStore;
        this.DocumentationStore = DocumentationStore;
        this.UsersStore = UsersStore;
        this.ProjectStore = ProjectStore;
        this.ProjectsListStore = ProjectsListStore;
    }
}

export default new RootStore();
