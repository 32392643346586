import axios, { AxiosError, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

declare const window: any;

const catchError = (err: AxiosError) => {
    if (err.response?.status === 401) {
        //clear cookies
        Cookies.remove('_DOC_AT');
        Cookies.remove('_DOC_ROLE');
        // reload current page
        document.location.reload();
    }
    throw err;
};

const catchResponse = (res: AxiosResponse) => {
    return res.data || res;
};

const request = async (
    url: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    data = null,
    params: object | null = null,
    headers = {},
) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get('_DOC_AT')}`;
    const init = {
        method,
        params: params
            ? { ...params, projectName: sessionStorage.getItem('projectName') }
            : { projectName: sessionStorage.getItem('projectName') },
        url,
        baseURL: window.baseURL,
        data,
        headers: {
            ...headers,
            accept: 'application/json',
            'content-type': 'application/json',
        },
    };
    return axios(init)
        .then((res: AxiosResponse) => catchResponse(res))
        .catch((err: AxiosError) => catchError(err));
};

export default class ProjectApiClient {
    static get = async (url: string, params: any | null = null) => request(url, 'GET', null, params);
    static post = async (url: string, data: any, params: any | null = null) => request(url, 'POST', data, params);
    static put = async (url: string, data: any, params: any | null = null) => request(url, 'PUT', data, params);
    static delete = async (url: string, params: any | null = null) => request(url, 'DELETE', null, params);
}
