import React, { useEffect } from 'react';
import { useParams, useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import Documentation from './Documentation';
import { CircularProgress } from '@material-ui/core';
import NotFoundPage from './404';

const Project: React.FC<{ ProjectStore?: any }> = inject('ProjectStore')(
    observer(({ ProjectStore }) => {
        const { projectname } = useParams<{ projectname: string }>();
        let match = useRouteMatch();

        useEffect(() => {
            projectname && ProjectStore.setName(projectname);
            return () => {
                ProjectStore.reset();
            };
        }, [ProjectStore, projectname]);

        if (ProjectStore.isPending) {
            return <Loading />;
        } else {
            return ProjectStore.isExist ? (
                <Switch>
                    <Route
                        path={match.url + '/documentation/:docname'}
                        render={() => {
                            return <Documentation />;
                        }}
                    />
                    <Route
                        exact
                        path={match.url + '/documentation'}
                        render={() => {
                            return <Documentation />;
                        }}
                    />

                    <Redirect from={match.url} to={match.url + '/documentation'} />
                </Switch>
            ) : (
                <Switch>
                    <Route
                        path="/*"
                        render={() => {
                            return <NotFoundPage />;
                        }}
                    />
                </Switch>
            );
        }
    }),
);

export default Project;

const Loading: React.FC = () => {
    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress style={{ color: 'rgb(25, 118, 210)' }} size={60} />
        </div>
    );
};
