import { types } from 'mobx-state-tree';

const Message = types.model({
    isOpen: types.optional(types.boolean, false),
    text: types.optional(types.string, ''),
    dontTranslate: types.optional(types.boolean, false),
    type: types.optional(types.string, ''),
});

const AppStore = types
    .model({
        locale: types.optional(types.string, 'uk'),
        message: types.optional(Message, {}),
    })
    .actions((self) => ({
        setLocale(value: string) {
            self.locale = value;
        },
        showMessage(text: string, type: 'error' | 'warning' | 'info' | 'success', dontTranslate: boolean = false) {
            self.message.text = text;
            self.message.dontTranslate = dontTranslate;
            self.message.type = type;
            self.message.isOpen = true;
        },
        hideMessage() {
            self.message.isOpen = false;
        },
    }));

export default AppStore.create();
