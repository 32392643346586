import React, { useRef } from 'react';
import { inject, observer } from 'mobx-react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ClickAwayListener, debounce, ListItemIcon } from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PeopleIcon from '@material-ui/icons/People';
import DeleteIcon from '@material-ui/icons/Delete';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import { fade, makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { useConfirm } from 'material-ui-confirm';

import LoginModal from './modals/LoginModal';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import '../styles/views/Search.scss';
import { IDoc } from '../stores/DocumentationStore';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        panel: {
            zIndex: 10,
        },
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            display: 'none',
            marginRight: theme.spacing(2),
            [theme.breakpoints.down(1025)]: {
                display: 'block',
            },
        },
        title: {
            display: 'none',
            textDecoration: 'none',
            color: '#fff',
            fontWeight: 500,
            fontSize: '20px',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.black, 0.05),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.black, 0.1),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '25ch',
            },
            [theme.breakpoints.up(720)]: {
                width: '40ch',
            },
        },
    }),
);

const TopPanel: React.FC<{ LoginStore?: any; DocumentationStore?: any; ProjectStore?: any }> = inject(
    'LoginStore',
    'DocumentationStore',
    'ProjectStore',
)(
    observer(({ LoginStore, DocumentationStore, ProjectStore }) => {
        const { t } = useTranslation();
        const history = useHistory();
        const classes = useStyles();
        const confirm = useConfirm();

        //for right menu
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const isMenuOpen = Boolean(anchorEl);
        const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        };
        const handleMenuClose = () => {
            setAnchorEl(null);
        };

        //for search
        const [isSearchListOpen, setIsSearchListOpen] = React.useState<boolean>(false);
        const searchInput = useRef<HTMLInputElement>(null);
        const handleSearchChange = debounce(DocumentationStore.searchDocs, 500);
        const handleCloseSearch = () => {
            if (searchInput.current) {
                searchInput.current.value = '';
            }
            setIsSearchListOpen(false);
        };
        const renderSearchList = (data: IDoc[]) => {
            if (Array.isArray(data) && data.length) {
                return data.map((item) => (
                    <Link
                        key={item.id}
                        to={`/project/${ProjectStore.projectName}/documentation/${item.Name}`}
                        className="search-list__item"
                        onClick={() => {
                            handleCloseSearch();
                        }}
                    >
                        <div className="search-list__item__name">
                            <span className="type">{item.Type}:</span>
                            <span className="name">{item.Name}</span>
                        </div>
                        <p className="search-list__item__description">{item.Description}</p>
                    </Link>
                ));
            } else {
                return <div className="search-list__empty">{t('search.empty')}</div>;
            }
        };

        const menuId = 'primary-search-account-menu';
        const renderMenu = (
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                id={menuId}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                {LoginStore.role === 'Admin' && (
                    <MenuItem
                        onClick={() => {
                            history.push('/users');
                            handleMenuClose();
                        }}
                    >
                        <ListItemIcon>
                            <PeopleIcon fontSize="small" />
                        </ListItemIcon>
                        {t('top-panel.list-of-users')}
                    </MenuItem>
                )}
                {LoginStore.role === 'Admin' && (
                    <MenuItem
                        onClick={() => {
                            history.push('/projectslist');
                            handleMenuClose();
                        }}
                    >
                        <ListItemIcon>
                            <LibraryBooksIcon fontSize="small" />
                        </ListItemIcon>
                        {t('top-panel.list-of-projects')}
                    </MenuItem>
                )}
                {(LoginStore.role === 'Admin' || LoginStore.role === 'Moderator') &&
                    ProjectStore.projectName !== '' &&
                    ProjectStore.isExist && (
                        <MenuItem
                            onClick={() => {
                                confirm({
                                    title: t('doc.delete-all-confirm-title'),
                                    description: t('doc.delete-all-confirm-description'),
                                    confirmationText: t('doc.delete-ok'),
                                    cancellationText: t('doc.delete-cancel'),
                                })
                                    .then(() => {
                                        DocumentationStore.deleteAllDocs(history);
                                        handleMenuClose();
                                    })
                                    .catch(() => {
                                        handleMenuClose();
                                    });
                            }}
                        >
                            <ListItemIcon>
                                <DeleteIcon fontSize="small" />
                            </ListItemIcon>
                            {t('top-panel.delete-all-docs')}
                        </MenuItem>
                    )}
                <MenuItem
                    onClick={() => {
                        LoginStore.handleLogOut();
                        if (ProjectStore.projectName === '' || !ProjectStore.isExist) {
                            history.replace('/project');
                        }
                        handleMenuClose();
                    }}
                >
                    <ListItemIcon>
                        <ExitToAppIcon fontSize="small" />
                    </ListItemIcon>
                    {t('top-panel.log-out')}
                </MenuItem>
            </Menu>
        );

        return (
            <div className={classes.panel}>
                <AppBar position="static" style={{ backgroundColor: '#1976d2' }}>
                    <Toolbar>
                        {history.location.pathname.includes('documentation') && (
                            <IconButton
                                edge="start"
                                id="doc-list-open-button"
                                className={classes.menuButton}
                                color="inherit"
                                aria-label="open drawer"
                                onClick={() => {
                                    DocumentationStore.setIsDocListOpen(!DocumentationStore.isDocListOpen);
                                }}
                            >
                                {DocumentationStore.isDocListOpen ? <CloseIcon /> : <MenuIcon />}
                            </IconButton>
                        )}
                        {ProjectStore.projectName !== '' && ProjectStore.isExist ? (
                            <Link to={`/project/${ProjectStore.projectName}/documentation`} className={classes.title}>
                                Documentation
                            </Link>
                        ) : (
                            <p className={classes.title}>Documentation</p>
                        )}

                        {ProjectStore.projectName !== '' && ProjectStore.isExist ? (
                            <ClickAwayListener onClickAway={handleCloseSearch}>
                                <div className={classes.search}>
                                    <div className={classes.searchIcon}>
                                        <SearchIcon />
                                    </div>
                                    <InputBase
                                        placeholder={`${t('top-panel.search')}...`}
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput,
                                        }}
                                        inputProps={{ 'aria-label': 'search' }}
                                        inputRef={searchInput}
                                        onChange={(event) => {
                                            handleSearchChange(event.target.value);
                                            if (event.target.value) {
                                                setIsSearchListOpen(true);
                                            } else {
                                                setIsSearchListOpen(false);
                                            }
                                        }}
                                    />
                                    {isSearchListOpen && (
                                        <div className="search-list">
                                            {renderSearchList(DocumentationStore.searchDocList)}
                                        </div>
                                    )}
                                </div>
                            </ClickAwayListener>
                        ) : (
                            ''
                        )}

                        <div className={classes.grow} />
                        <div>
                            {LoginStore.isLogged ? (
                                <IconButton
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    onClick={handleProfileMenuOpen}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                </IconButton>
                            ) : (
                                <Button
                                    color="inherit"
                                    onClick={() => {
                                        LoginStore.modal.show();
                                    }}
                                >
                                    {t('top-panel.log-in')}
                                </Button>
                            )}
                        </div>
                    </Toolbar>
                </AppBar>
                {renderMenu}
                {<LoginModal />}
            </div>
        );
    }),
);
export default TopPanel;
