import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import './styles/App.scss';

import TopPanel from './views/TopPanel';
import Users from './views/Users';
import ProjectsList from './views/ProjectsList';
import Project from './views/Project';

const Page = inject('LoginStore')(
    observer(({ LoginStore }: any) => {
        return (
            <WorkSpace>
                <Switch>
                    <Route
                        exact
                        path="/users"
                        render={() => {
                            if (LoginStore.isLogged && LoginStore.role === 'Admin') {
                                return <Users />;
                            } else {
                                return <Redirect to="/project" />;
                            }
                        }}
                    />
                    <Route
                        exact
                        path="/projectslist"
                        render={() => {
                            if (LoginStore.isLogged && LoginStore.role === 'Admin') {
                                return <ProjectsList />;
                            } else {
                                return <Redirect to="/project" />;
                            }
                        }}
                    />
                    <Route
                        path="/project/:projectname"
                        render={() => {
                            return <Project />;
                        }}
                    />
                    <Route
                        exact
                        path="/project"
                        render={() => {
                            return <Project />;
                        }}
                    />
                    <Route
                        path="/"
                        render={() => {
                            return <Redirect to="/project" />;
                        }}
                    />
                </Switch>
            </WorkSpace>
        );
    }),
);

interface WorkSpaceProps {
    AppStore?: any;
    children?: React.ReactNode;
}
const WorkSpace: React.FC<WorkSpaceProps> = inject('AppStore')(
    observer(({ AppStore, children }) => {
        const { t } = useTranslation();
        const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
            if (reason === 'clickaway') {
                return;
            }
            AppStore.hideMessage();
        };
        return (
            <div className="app">
                <TopPanel />
                <div className="app__body">{children}</div>
                <div>
                    <Snackbar open={AppStore.message.isOpen} autoHideDuration={4000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity={AppStore.message.type}>
                            {AppStore.message.dontTranslate ? AppStore.message.text : t(AppStore.message.text)}
                        </Alert>
                    </Snackbar>
                </div>
            </div>
        );
    }),
);

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Loader: React.FC = () => (
    <div className="Loader--container">
        <CircularProgress style={{ color: 'rgb(25, 118, 210)' }} size={60} />
    </div>
);

// here app catches the suspense from page in case translations are not yet loaded
export default function App() {
    return (
        <Suspense fallback={<Loader />}>
            <Page />
        </Suspense>
    );
}
