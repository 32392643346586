import { types, Instance } from 'mobx-state-tree';
import ApiClient from '../services/ApiClient';
import FieldValidator from '../services/FieldValidator';
import AppStore from './AppStore';

export interface IProject extends Instance<typeof Project> {}
export interface IStatus {
    status: 0 | 1 | 2;
    message: string;
}

const ModalProject = types.model({
    id: types.optional(types.number, -1),
    Name: types.optional(types.string, ''),
    Description: types.optional(types.string, ''),
});

const Project = types.model({
    id: types.number,
    Name: types.string,
    Description: types.string,
});

const Modal = types
    .model({
        isOpen: types.optional(types.boolean, false),
        project: types.optional(ModalProject, {}),
        nameError: types.optional(types.boolean, false),
        descriptionError: types.optional(types.boolean, false),
        projectExist: types.optional(types.boolean, false),
    })
    .actions((self) => ({
        show() {
            self.isOpen = true;
        },
        hide() {
            self.isOpen = false;
            this.reset();
        },
        setProject(data: IProject) {
            self.project = data;
        },
        setProjectExist(value: boolean) {
            self.projectExist = true;
        },
        reset() {
            self.project.id = -1;
            self.project.Name = '';
            self.project.Description = '';
            self.projectExist = false;
            self.nameError = false;
            self.descriptionError = false;
        },
        handleChange(field: string, value: string) {
            if (field) {
                switch (field) {
                    case 'Name':
                        self.project.Name = value;
                        break;
                    case 'Description':
                        self.project.Description = value;
                        break;
                    default:
                        break;
                }
            }
        },
        validate() {
            let valid = true;
            if (!FieldValidator.isNotEmpty(self.project.Name)) {
                valid = false;
                self.nameError = true;
            } else {
                self.nameError = false;
            }
            if (!FieldValidator.isNotEmpty(self.project.Description)) {
                valid = false;
                self.descriptionError = true;
            } else {
                self.descriptionError = false;
            }
            return valid;
        },
    }));

const ProjectsListStore = types
    .model({
        isLoaded: types.optional(types.boolean, false),
        isPending: types.optional(types.boolean, false),
        listOfProjects: types.optional(types.frozen(types.array(Project)), []),
        modal: types.optional(Modal, {}),
    })
    .actions((self) => ({
        setLoaded(value: boolean) {
            self.isLoaded = value;
        },
        setPending(value: boolean) {
            self.isPending = value;
        },
        setList(data: IProject[]) {
            self.listOfProjects = data;
        },
        reset() {
            self.isLoaded = false;
            self.isPending = false;
        },
    }))
    .actions((self) => ({
        getList(reset: boolean = false) {
            if ((!self.isLoaded && !self.isPending) || reset) {
                self.setPending(true);
                ApiClient.get('/Project/GetProjectsList')
                    .then((res: IProject[]) => {
                        self.setList(res);
                        self.setLoaded(true);
                        self.setPending(false);
                    })
                    .catch((err) => {
                        self.setLoaded(true);
                        self.setPending(false);
                    });
            }
        },
        showModal(id?: number | undefined) {
            if (id !== undefined) {
                const project = self.listOfProjects?.find((project) => project.id === id);
                if (project) {
                    self.modal.setProject(project);
                    self.modal.show();
                }
            } else {
                self.modal.show();
            }
        },
    }))
    .actions((self) => ({
        createProject() {
            if (self.modal.validate()) {
                ApiClient.post('/Project/CreateProject', {
                    Name: self.modal.project.Name,
                    Description: self.modal.project.Description,
                })
                    .then((res: IStatus) => {
                        if (res.status === 1) {
                            AppStore.showMessage('projects.project-create-success', 'success');
                            self.modal.hide();
                            self.getList(true);
                        } else if (res.status === 0) {
                            self.modal.setProjectExist(true);
                        } else if (res.status === 2) {
                            AppStore.showMessage('projects.project-create-error', 'error');
                            self.modal.hide();
                        }
                    })
                    .catch((error) => {
                        AppStore.showMessage('projects.project-create-error', 'error');
                        self.modal.hide();
                    });
            }
        },
        updateProject() {
            if (self.modal.validate()) {
                ApiClient.post('/Project/UpdateProject', {
                    id: self.modal.project.id,
                    Name: self.modal.project.Name,
                    Description: self.modal.project.Description,
                })
                    .then((res: IStatus) => {
                        if (res.status === 1) {
                            AppStore.showMessage('projects.project-update-success', 'success');
                            self.modal.hide();
                            self.getList(true);
                        } else if (res.status === 0) {
                            AppStore.showMessage('projects.project-update-error', 'error');
                            self.modal.hide();
                        } else if (res.status === 2) {
                            AppStore.showMessage('projects.project-update-error', 'error');
                            self.modal.hide();
                        }
                    })
                    .catch((err) => {
                        AppStore.showMessage('projects.project-update-error', 'error');
                        self.modal.hide();
                    });
            }
        },
        deleteProject(id: number) {
            const project = self.listOfProjects?.find((project) => project.id === id);
            if (project) {
                ApiClient.delete('/Project/DeleteProject', { projectName: project.Name })
                    .then((res) => {
                        AppStore.showMessage('projects.project-delete-success', 'success');
                        self.getList(true);
                    })
                    .catch((err) => {
                        AppStore.showMessage('projects.project-delete-error', 'error');
                    });
            }
        },
    }))
    .actions((self) => ({
        handleAccept() {
            if (self.modal.project.id === -1) {
                self.createProject();
            } else {
                self.updateProject();
            }
        },
    }));

export default ProjectsListStore.create();
