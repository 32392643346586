import React from 'react';
import i404 from '../img/404.png';
import '../styles/views/404.scss';

const NotFoundPage: React.FC = () => {
    return (
        <div className="page-404">
            <div className="page-404__image-container">
                <img src={i404} alt="Page not Found" className="page-404__image" />
            </div>
        </div>
    );
};

export default NotFoundPage;
