import React from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ProjectModal: React.FC = inject('ProjectsListStore')(
    observer(({ ProjectsListStore }: any) => {
        const { t } = useTranslation();
        return (
            <div>
                <Dialog
                    open={ProjectsListStore.modal.isOpen}
                    onClose={() => {
                        ProjectsListStore.modal.hide();
                    }}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title" color="green">
                        {ProjectsListStore.modal.project.id === -1 ? t('projects.add-project') : t('projects.edit-project')}
                    </DialogTitle>
                    <DialogContent>
                        {ProjectsListStore.modal.projectExist && (
                            <DialogContentText color="secondary">{t('projects.project-exist')}</DialogContentText>
                        )}
                        <TextField
                            required
                            autoFocus
                            margin="dense"
                            id="project-name"
                            label={t('projects.name')}
                            type="text"
                            fullWidth
                            error={ProjectsListStore.modal.nameError}
                            helperText={ProjectsListStore.modal.nameError && t('users.empty-field')}
                            value={ProjectsListStore.modal.project.Name}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                ProjectsListStore.modal.handleChange('Name', event.target.value);
                            }}
                        />
                        <TextField
                            required
                            margin="dense"
                            id="project-description"
                            label={t('projects.description')}
                            type="text"
                            fullWidth
                            error={ProjectsListStore.modal.descriptionError}
                            helperText={ProjectsListStore.modal.descriptionError && t('users.empty-field')}
                            value={ProjectsListStore.modal.project.Description}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                ProjectsListStore.modal.handleChange('Description', event.target.value);
                            }}
                        />                     
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                ProjectsListStore.modal.hide();
                            }}
                            color="secondary"
                        >
                            {t('projects.decline')}
                        </Button>
                        <Button
                            onClick={() => {
                                ProjectsListStore.handleAccept();
                            }}
                            color="primary"
                        >
                            {t('projects.accept')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }),
);

export default ProjectModal;
