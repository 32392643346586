import { types, Instance } from 'mobx-state-tree';
import ProjectApiClient from '../services/ProjectApiClient';
import AppStore from './AppStore';

export interface IDocListItem {
    name: string;
    items: { name: string; id: number }[];
}
export interface IUploadFilesStatus {
    uploaded: IFileStatus[];
    failed: IFileStatus[];
}
export interface IFileStatus {
    filename: string;
    message: string;
}

export interface IDoc extends Instance<typeof Doc> {}
export interface IFullDoc extends Instance<typeof FullDoc> {}

//models for doc navigation

const Doc = types.model({
    id: types.number,
    Name: types.string,
    Description: types.string,
    Type: types.string,
});

const DocListItem = types.model({
    id: types.number,
    name: types.string,
});

const DocList = types.model({
    name: types.string,
    items: types.optional(types.frozen(types.array(DocListItem)), []),
});

//models for doc overview

const InputParameter = types.model({
    id: types.number,
    ParamName: types.string,
    ParamType: types.string,
    Comment: types.string,
});

const OutputParameter = types.model({
    id: types.number,
    ParamName: types.string,
    ParamType: types.string,
    Comment: types.string,
});

const Reference = types.model({
    id: types.number,
    Type: types.string,
    Name: types.string,
});

const History = types.model({
    id: types.number,
    Text: types.string,
});

const FullDoc = types.model({
    id: types.optional(types.number, 0),
    Name: types.optional(types.string, ''),
    Description: types.optional(types.string, ''),
    Type: types.optional(types.string, ''),
    InputParameters: types.optional(types.frozen(types.array(InputParameter)), []),
    OutputParameters: types.optional(types.frozen(types.array(OutputParameter)), []),
    References: types.optional(types.frozen(types.array(Reference)), []),
    History: types.optional(types.frozen(types.array(History)), []),
});

const DocumentationStore = types
    .model({
        isLoaded: types.optional(types.boolean, false),
        isPending: types.optional(types.boolean, false),
        docName: types.optional(types.string, ''),
        isDocListOpen: types.optional(types.boolean, false),
        docList: types.optional(types.frozen(types.array(Doc)), []),
        groupedDocList: types.optional(types.frozen(types.array(DocList)), []),
        fullDoc: types.optional(FullDoc, {}),
        searchDocList: types.optional(types.frozen(types.array(Doc)), []),
        uploadPending: types.optional(types.boolean, false),
    })
    .views((self) => ({
        get syntaxString() {
            let format = (type: string) => {
                if (type.toLowerCase() === 'int' || type.toLowerCase() === 'real' || type.toLowerCase() === 'float') {
                    return '0';
                } else if (type.toLowerCase().startsWith('date')) {
                    let dt = new Date();
                    let year = dt.getFullYear();
                    let month = String(dt.getMonth() + 1).padStart(2, '0');
                    let day = String(dt.getDate()).padStart(2, '0');
                    return `datefromparts( ${year}, ${month}, ${day} )`;
                } else if (type.toLowerCase().startsWith('datetime')) {
                    let dt = new Date();
                    let year = dt.getFullYear();
                    let month = String(dt.getMonth() + 1).padStart(2, '0');
                    let day = String(dt.getDate()).padStart(2, '0');
                    let hour = String(dt.getHours()).padStart(2, '0');
                    let minutes = String(dt.getMinutes()).padStart(2, '0');
                    let seconds = String(dt.getSeconds()).padStart(2, '0');
                    let miliseconds = String(dt.getMilliseconds()).padStart(3, '0');
                    return `datetimefromparts( ${year}, ${month}, ${day}, ${hour}, ${minutes}, ${seconds}, ${miliseconds} )`;
                } else {
                    return '""';
                }
            };
            let res_str = '';
            if (self.fullDoc.Type.toLowerCase() === 'function') {
                res_str += 'select ';
                self.fullDoc.OutputParameters?.forEach((o_p, index, array) => {
                    res_str += o_p.ParamName;
                    if (index + 1 !== array.length) {
                        res_str += ',';
                    }
                    res_str += ' ';
                });
                res_str += 'from ';
                res_str += self.fullDoc.Name.startsWith('dbo.') ? self.fullDoc.Name.slice(4) : self.fullDoc.Name;
                res_str += '( ';
                self.fullDoc.InputParameters?.forEach((i_p, index, array) => {
                    res_str += format(i_p.ParamType);
                    if (index + 1 !== array.length) {
                        res_str += ',';
                    }
                    res_str += ' ';
                });
                res_str += ' )';
            } else if (self.fullDoc.Type.toLowerCase() === 'procedure') {
                res_str += 'exec ';
                res_str += self.fullDoc.Name.startsWith('dbo.') ? self.fullDoc.Name.slice(4) : self.fullDoc.Name;
                res_str += ' ';
                self.fullDoc.InputParameters?.forEach((i_p, index, array) => {
                    res_str += format(i_p.ParamType);
                    if (index + 1 !== array.length) {
                        res_str += ',';
                        res_str += ' ';
                    }
                });
            }
            return res_str;
        },
    }))
    .actions((self) => ({
        setLoaded(value: boolean) {
            self.isLoaded = value;
        },
        setPending(value: boolean) {
            self.isPending = value;
        },
        setUploadPending(value: boolean) {
            self.uploadPending = value;
        },
        setIsDocListOpen(value: boolean) {
            self.isDocListOpen = value;
        },
        setDocList(data: IDoc[]) {
            self.docList = data;
        },
        setSearchDocList(data: IDoc[]) {
            self.searchDocList = data;
        },
        setGroupedList(data: IDoc[]) {
            let res_arr: IDocListItem[] = [];
            data.forEach((doc) => {
                const res = res_arr.find((el) => el.name === doc.Type);
                if (res) {
                    res.items.push({ name: doc.Name, id: doc.id });
                } else {
                    res_arr.push({
                        name: doc.Type,
                        items: [{ name: doc.Name, id: doc.id }],
                    });
                }
            });
            self.groupedDocList = res_arr;
        },
        setFullDoc(obj: IFullDoc) {
            self.fullDoc = obj;
        },
        reset() {
            self.isLoaded = false;
            self.isPending = false;
            self.uploadPending = false;
            self.isDocListOpen = false;
            self.docName = '';
            self.searchDocList = [];
        },
    }))
    .actions((self) => ({
        getList(reset = false) {
            if ((!self.isLoaded && !self.isPending) || reset) {
                self.setPending(true);
                ProjectApiClient.get('/Doc/GetDocList')
                    .then((res: IDoc[]) => {
                        if (Array.isArray(res)) {
                            self.setDocList(res);
                            self.setGroupedList(res);
                        }
                        self.setLoaded(true);
                        self.setPending(false);
                    })
                    .catch((err) => {
                        self.setPending(false);
                    });
            }
        },
        getDoc(name: string, history: any) {
            if (name && self.docName !== name) {
                self.docName = name;
                if (self.docList?.length) {
                    //check that there is such doc  in doclist
                    if (Array.isArray(self.docList) && self.docList.find((el) => el.Name === name)) {
                        ProjectApiClient.get('/Doc/GetDoc', { name }).then((res: IFullDoc) => {
                            if (res) {
                                self.setFullDoc(res);
                            }
                        });
                    } else {
                        //redirect to /documentation if there is no such doc  in doclist
                        history.replace(`/project/${sessionStorage.getItem('projectName')}/documentation`);
                    }
                } else {
                    ProjectApiClient.get('/Doc/GetDocList')
                        .then((res: IDoc[]) => {
                            self.setDocList(res);
                            //check that there is such doc  in doclist
                            if (Array.isArray(res) && res.find((el) => el.Name === name)) {
                                ProjectApiClient.get('/Doc/GetDoc', { name }).then((res: IFullDoc) => {
                                    if (res) {
                                        self.setFullDoc(res);
                                    }
                                });
                            } else {
                                history.replace(`/project/${sessionStorage.getItem('projectName')}/documentation`);
                            }
                        })
                        .catch((err) => {});
                }
            } else if (!name) {
                history.replace(`/project/${sessionStorage.getItem('projectName')}/documentation`);
            }
        },
    }))
    .actions((self) => ({
        deleteDoc(history: any) {
            ProjectApiClient.delete('/Doc/DeleteDoc', { name: self.fullDoc.Name })
                .then((res) => {
                    self.getList(true);
                    history.replace(`/project/${sessionStorage.getItem('projectName')}/documentation`);
                    AppStore.showMessage('doc.message.delete-succes', 'success');
                })
                .catch((err) => {
                    AppStore.showMessage('doc.message.delete-error', 'error');
                });
        },
        uploadDocs(files: any[], history: any) {
            if (files.length) {
                self.setUploadPending(true);
                let data = new FormData();
                for (let i = 0; i < files.length; i++) {
                    data.append('files', files[i]);
                }
                ProjectApiClient.post('/Doc/UploadDocs', data)
                    .then((res: IUploadFilesStatus) => {
                        if (res.failed.length === 0) {
                            self.getList(true);
                            history.replace(`/project/${sessionStorage.getItem('projectName')}/documentation`);
                            AppStore.showMessage('Успішно завантажено усі документи!', 'success', true);
                        } else if (res.uploaded.length === 0) {
                            console.log(res);
                            AppStore.showMessage(
                                'Не вдалося завантажити документи! Детальна інформація в консолі.',
                                'error',
                                true,
                            );
                        } else {
                            let summ = res.uploaded.length + res.failed.length;
                            self.getList(true);
                            history.replace(`/project/${sessionStorage.getItem('projectName')}/documentation`);
                            console.log(res);
                            AppStore.showMessage(
                                `Успішно завантажено ${res.uploaded.length} з ${summ} документів! Детальна інформація в консолі.`,
                                'success',
                                true,
                            );
                        }
                        self.setUploadPending(false);
                    })
                    .catch((err) => {
                        AppStore.showMessage('doc.message.upload-error', 'error');
                        self.setUploadPending(false);
                    });
            }
        },
        searchDocs(searchString: string) {
            if (searchString) {
                ProjectApiClient.get('/Doc/SearchDocs', { search: searchString })
                    .then((res: IDoc[]) => {
                        if (Array.isArray(res)) {
                            self.setSearchDocList(res);
                        }
                    })
                    .catch((err) => {});
            } else {
                self.setSearchDocList([]);
            }
        },
        deleteAllDocs(history: any) {
            ProjectApiClient.delete('/Doc/DeleteAllDocs')
                .then((res: { count: number }) => {
                    self.getList(true);
                    history.replace(`/project/${sessionStorage.getItem('projectName')}/documentation`);
                    AppStore.showMessage('doc.message.delete-all-success', 'success');
                })
                .catch((err) => {
                    AppStore.showMessage('doc.message.delete-all-error', 'error');
                });
        },
    }));

export default DocumentationStore.create();
