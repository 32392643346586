import React from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { InputLabel, MenuItem, Select } from '@material-ui/core';

const UserModal: React.FC = inject('UsersStore')(
    observer(({ UsersStore }: any) => {
        const { t } = useTranslation();
        return (
            <div>
                <Dialog
                    open={UsersStore.modal.isOpen}
                    onClose={() => {
                        UsersStore.modal.hide();
                    }}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title" color="green">
                        {UsersStore.modal.user.id === -1 ? t('users.add-user') : t('users.edit-user')}
                    </DialogTitle>
                    <DialogContent>
                        {UsersStore.modal.userExist && (
                            <DialogContentText color="secondary">{t('users.user-exist')}</DialogContentText>
                        )}
                        <TextField
                            required
                            autoFocus
                            autoComplete="new-login"
                            margin="dense"
                            id="users-login"
                            label={t('users.login')}
                            type="text"
                            fullWidth
                            error={UsersStore.modal.loginError}
                            helperText={UsersStore.modal.loginError && t('users.empty-field')}
                            value={UsersStore.modal.user.login}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                UsersStore.modal.handleChange('login', event.target.value);
                            }}
                        />
                        <TextField
                            autoComplete="new-password"
                            margin="dense"
                            id="users-password"
                            label={t('users.password')}
                            type="password"
                            fullWidth
                            error={UsersStore.modal.passwordError}
                            helperText={
                                UsersStore.modal.passwordError &&
                                (UsersStore.modal.user.id === -1 && UsersStore.modal.user.password === ''
                                    ? t('users.empty-field')
                                    : t('users.weak-password'))
                            }
                            value={UsersStore.modal.user.password}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                UsersStore.modal.handleChange('password', event.target.value);
                            }}
                        />
                        <InputLabel required id="user-role-select-label">
                            {t('users.role')}
                        </InputLabel>
                        <Select
                            labelId="user-role-select-label"
                            fullWidth
                            id="user-role-select"
                            value={UsersStore.modal.user.role}
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                UsersStore.modal.handleChange('role', event.target.value);
                            }}
                        >
                            {UsersStore.roles.map((role: any) => (
                                <MenuItem key={role.id} value={role.role}>
                                    {role.role}
                                </MenuItem>
                            ))}
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                UsersStore.modal.hide();
                            }}
                            color="secondary"
                        >
                            {t('users.decline')}
                        </Button>
                        <Button
                            onClick={() => {
                                UsersStore.handleAccept();
                            }}
                            color="primary"
                        >
                            {t('users.accept')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }),
);

export default UserModal;
