import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { IReturnUser } from '../stores/UsersStore';

import { IconButton, Tooltip } from '@material-ui/core';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { useConfirm } from 'material-ui-confirm';

import UserModal from './modals/UserModal';

import '../styles/views/Users.scss';
import '../styles/views/Table.scss';

const Users: React.FC = inject('UsersStore')(
    observer(({ UsersStore }: any) => {
        const { t } = useTranslation();
        const confirm = useConfirm();

        !UsersStore.isLoaded && UsersStore.getList();

        useEffect(() => {
            return () => {
                UsersStore.reset();
            };
        }, [UsersStore]);

        const renderTable = (data: IReturnUser[]) => {
            return (
                <>
                    <div className="table-head">
                        <div className="table-head__column">{t('users.login')}</div>
                        <div className="table-head__column">{t('users.role')}</div>
                        <div className="table-head__column button"></div>
                        <div className="table-head__column button"></div>
                    </div>
                    <div className="table-body">
                        {data.map((user) => (
                            <div className="table-body__row" key={user.id}>
                                <div className="table-body__column">{user.login}</div>
                                <div className="table-body__column">{user.role}</div>
                                <div className="table-body__column button">
                                    <Tooltip title={String(t('users.edit-user'))} aria-label="edit-user">
                                        <IconButton
                                            aria-label="delete"
                                            className="table-body__column-button"
                                            onClick={() => {
                                                UsersStore.showModal(user.id);
                                            }}
                                        >
                                            <EditOutlinedIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <div className="table-body__column button">
                                    <Tooltip title={String(t('users.delete-user'))} aria-label="delete-user">
                                        <IconButton
                                            aria-label="delete"
                                            className="table-body__column-button"
                                            onClick={() => {
                                                confirm({
                                                    title: t('users.delete-confirm-title'),
                                                    description: t('users.delete-confirm-description'),
                                                    confirmationText: t('doc.delete-ok'),
                                                    cancellationText: t('doc.delete-cancel'),
                                                })
                                                    .then(() => {
                                                        UsersStore.deleteUser(user.id);
                                                    })
                                                    .catch(() => {});
                                            }}
                                        >
                                            <DeleteOutlineOutlinedIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            );
        };
        return (
            <div className="main-container">
                <div className="users-container">
                    <div className="users">
                        <div className="users__header">
                            <h1>{t('users.title')}</h1>
                            <Tooltip title={String(t('users.add-user'))} aria-label="add user">
                                <IconButton
                                    aria-label="add"
                                    className="users__header-button"
                                    onClick={() => {
                                        UsersStore.showModal();
                                    }}
                                >
                                    <PersonAddIcon fontSize="small" style={{ color: 'rgb(25, 118, 210)' }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="table-list">{renderTable(UsersStore.listOfUsers)}</div>
                    </div>
                </div>
                <UserModal />
            </div>
        );
    }),
);

export default Users;
