import React from 'react';
import { inject, observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';

const LoginModal: React.FC = inject('LoginStore')(
    observer(({ LoginStore }: any) => {
        const { t } = useTranslation();
        return (
            <div>
                <Dialog
                    open={LoginStore.modal.isOpen}
                    onClose={() => {
                        LoginStore.modal.hide();
                    }}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title" color="green">
                        {t('login.title')}
                    </DialogTitle>
                    <DialogContent>
                        {LoginStore.modal.userDoesntExist && (
                            <DialogContentText color="secondary">
                                {t("login.invalid-data")}
                            </DialogContentText>
                        )}
                        <TextField
                            required
                            autoFocus
                            margin="dense"
                            id="login"
                            label={t('login.login')}
                            type="text"
                            fullWidth
                            error={LoginStore.modal.loginError}
                            helperText={LoginStore.modal.loginError && t('login.empty')}
                            value={LoginStore.modal.login}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                LoginStore.modal.setLogin(event.target.value);
                            }}
                        />
                        <TextField
                            required
                            margin="dense"
                            id="password"
                            label={t('login.password')}
                            type="password"
                            fullWidth
                            error={LoginStore.modal.passwordError}
                            helperText={LoginStore.modal.passwordError && t('login.empty')}
                            value={LoginStore.modal.password}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                LoginStore.modal.setPassword(event.target.value);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                LoginStore.modal.hide();
                            }}
                            color="secondary"
                        >
                            {t('login.cancel')}
                        </Button>
                        <Button
                            onClick={() => {
                                LoginStore.handleLogIn();
                            }}
                            color="primary"
                        >
                            {t('login.ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }),
);

export default LoginModal;
